import posthog from 'posthog-js'
import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin({
  name: 'posthog',
  async setup(_nuxtApp) {
    useLogger().debug('Initialising Posthog client...', '#posthog')

    const runtimeConfig = useRuntimeConfig()

    // To avoid spamming the console.. posthog debug is disabled by default:
    const disableDebug = runtimeConfig.public.posthog.disableDebug

    const posthogClient = posthog.init(runtimeConfig.public.posthog.publicKey, {
      api_host: runtimeConfig.public.posthog.host,
      person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
      capture_pageview: false, // we add manual pageview capturing below
      loaded: (posthog) => {
        if (import.meta.env.MODE === 'development') {
          posthog.debug(!disableDebug) // To disable debugging in development, set disableDebug to true in runtimeConfig
        }
      },
    })

    // Make sure that pageviews are captured with each route change
    const router = useRouter()
    router.afterEach((to) => {
      nextTick(() => {
        posthog.capture('$pageview', {
          current_url: to.fullPath,
        })
      })
    })

    useLogger().debug('Finished Posthog initialisation', '#posthog')

    return {
      provide: {
        posthog: () => posthogClient,
      },
    }
  },
})
