// stores/userAppState.ts
import { defineStore } from 'pinia'

interface AppStateItem {
  key: string
  value: any
}

type AppState = Record<string, any>

export const useUserAppStateStore = defineStore('userAppState', () => {
  const user = useSupabaseUser()
  const appState = ref<AppState>({})
  const isLoading = ref(false)
  const logger = useLogger()

  /**
   * Fetches the user's application state from the server.
   *
   * This function performs the following steps:
   * 1. Checks if a user is logged in.
   * 2. Makes an API request to fetch the user's app state.
   * 3. Transforms the fetched data into the appropriate format.
   * 4. Updates the local appState with the fetched data.
   *
   * @async
   * @function fetchAppState
   *
   * Side effects:
   * - Sets isLoading state during the operation.
   * - Updates appState with fetched data or an empty object if no data is returned.
   * - Logs debug and error messages.
   *
   * @throws {Error} The function catches any errors internally and logs them, but doesn't throw.
   *
   * @returns {Promise<void>}
   */
  const fetchAppState = async () => {
    if (!user.value) {
      logger.debug('useUserAppState: No user, returning empty app state')
      return
    }

    isLoading.value = true
    logger.debug('useUserAppState: Fetching app state')
    try {
      const data = await $fetch<AppStateItem[]>('/api/v2/state/user-app-state-fetch', {
        headers: useRequestHeaders(['cookie']),
      })

      if (data) {
        logger.debug('useUserAppState: App state fetched successfully')
        appState.value = data.reduce<AppState>((acc, item) => {
          acc[item.key] = item.value
          return acc
        }, {})
      }
      else {
        logger.debug('useUserAppState: No app state data returned')
        appState.value = {}
      }
    }
    catch (error) {
      logger.error('useUserAppState: Failed to fetch app state:', error)
      appState.value = {}
    }
    finally {
      isLoading.value = false
    }
  }

  /**
   * Updates a specific key-value pair in the user's application state.
   *
   * This function performs the following steps:
   * 1. Checks if a user is logged in.
   * 2. Makes an API request to update the specified key-value pair in the user's app state.
   * 3. Updates the local appState with the new value if the API request is successful.
   *
   * @async
   * @function updateAppState
   * @param {string} key - The key of the app state item to update.
   * @param {any} value - The new value to set for the specified key.
   *
   * Side effects:
   * - Updates the server-side app state via API call.
   * - Updates the local appState if the API call is successful.
   * - Logs debug and error messages.
   *
   * @throws {Error} The function catches any errors internally and logs them, but doesn't throw.
   *
   * @returns {Promise<void>}
   */
  const updateAppState = async (key: string, value: any) => {
    if (!user.value) {
      logger.debug('useUserAppState: No user, cannot update app state')
      return
    }

    try {
      logger.debug(`useUserAppState: Updating app state for key: ${key}`)
      await $fetch('/api/v2/state/user-app-state-update', {
        method: 'POST',
        body: { key, value },
        headers: useRequestHeaders(['cookie']),
      })
      appState.value[key] = value
      logger.debug(`useUserAppState: App state updated successfully for key: ${key}`)
    }
    catch (error) {
      logger.error('useUserAppState: Failed to update app state:', error)
    }
  }

  // Initialize the store
  fetchAppState()

  // Watch for user changes
  watch(user, () => {
    if (user.value) {
      logger.debug('useUserAppState: User changed, refreshing app state')
      fetchAppState()
    }
    else {
      logger.debug('useUserAppState: User logged out, clearing app state')
      appState.value = {}
    }
  })

  // Getter
  const currentOrganizationId = computed(() => appState.value.ORGANIZATION || null)

  return {
    appState: readonly(appState),
    isLoading: readonly(isLoading),
    updateAppState,
    refreshAppState: fetchAppState,
    currentOrganizationId,
  }
})

export type UserAppState = ReturnType<typeof useUserAppStateStore>

// Enable HMR
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserAppStateStore, import.meta.hot))
}
