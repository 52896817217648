// Function to wrap posthog, gtag and any other analytics tools for event capture throughout the app.
// Accepts an event tag (verb) eg 'user_signed_up' and an optional object of properties to send with the event.
export default async function useEventCapture(eventName: string, properties?: Record<string, any>) {
  const { $posthog } = useNuxtApp()
  // const { gtag } = useGtag() // gtag instance not needed when using useTrackEvent()
  const log = useLogger()
  const posthog = $posthog()

  try {
    // PostHog event capture
    if (posthog) {
      posthog.capture(eventName, properties)
    }

    // useTrackEvent is expopsed by the gtag nuxt module
    // This composable is SSR-ready. But since the gtag.js instance is available in the client only, executing the composable on the server will have no effect.
    useTrackEvent(eventName, properties)

    // We can add more analytics tools here if needed

    log.debug(`Event captured: ${eventName}`, '#useEventCapture', properties)
  }
  catch (error) {
    log.error('Error capturing event:', error)
  }
}
