/* eslint no-console: ["error", { allow: ["warn", "log", "error", "debug"] }] */

// A logging wrapper for client-side logging.

import { useRuntimeConfig } from '#app'
import * as Sentry from '@sentry/vue'

type LogLevel = 'debug' | 'log' | 'warn' | 'error'
type Tag = `#${string}`
type LogFunction = (message: string, ...args: (Tag | any)[]) => void

const getTagStyles = (() => {
  const styles: Record<LogLevel, string> = {
    debug: 'background-color: #9C27B0; color: white;',
    log: 'background-color: #4CAF50; color: white;',
    warn: 'background-color: #FFC107; color: black;',
    error: 'background-color: #F44336; color: white;',
  }
  const baseStyles = 'font-weight: bold; padding: 2px 4px; border-radius: 2px;'

  return (level: LogLevel) => `${baseStyles} ${styles[level]}`
})()

const mapLogLevelToSentryLevel = (level: LogLevel): Sentry.SeverityLevel => {
  switch (level) {
    case 'debug':
    case 'log':
      return 'info'
    case 'warn':
      return 'warning'
    case 'error':
      return 'error'
    default:
      // eslint-disable-next-line no-case-declarations
      const _exhaustiveCheck: never = level
      return _exhaustiveCheck
  }
}

export const useLogger = () => {
  const config = useRuntimeConfig()

  const createLogFunction = (consoleMethod: LogFunction, level: LogLevel): LogFunction => {
    return (message: string, ...args: (Tag | any)[]) => {
      if (config.public.DEBUG || level === 'error') {
        const tags = args.filter((arg): arg is Tag => typeof arg === 'string' && arg.startsWith('#'))
        const otherArgs = args.filter(arg => typeof arg !== 'string' || !arg.startsWith('#'))

        const tagStyles = getTagStyles(level)
        const tagString = tags.map(tag => `%c${tag}%c`).join(' ')
        const styleArgs = tags.flatMap(_tag => [tagStyles, ''])

        consoleMethod(`${tagString} ${message}`, ...styleArgs, ...otherArgs)

        // Send to Sentry
        if (config.public.SENTRY_ENABLED) {
          const sentryLevel = mapLogLevelToSentryLevel(level)
          const errorObject = otherArgs.find(arg => arg instanceof Error)
          if (errorObject && level === 'error') {
            Sentry.captureException(errorObject, {
              level: sentryLevel,
              tags: Object.fromEntries(tags.map(tag => [tag.slice(1), true])),
              extra: otherArgs.length ? { args: otherArgs.filter(arg => arg !== errorObject) } : undefined,
            })
          }
          else {
            Sentry.captureMessage(message, {
              level: sentryLevel,
              tags: Object.fromEntries(tags.map(tag => [tag.slice(1), true])),
              extra: otherArgs.length ? { args: otherArgs } : undefined,
            })
          }
        }
      }
    }
  }

  return {
    debug: createLogFunction(console.debug, 'debug'),
    log: createLogFunction(console.log, 'log'),
    warn: createLogFunction(console.warn, 'warn'),
    error: createLogFunction(console.error, 'error'),
  }
}

// Example usage:
// const logger = useLogger()
// logger.debug('Detailed information for debugging', '#debug', '#verbose', { someData: 'value' })
// logger.log('User logged in', '#auth', '#userAction', { userId: 123 })
// logger.warn('API rate limit approaching', '#api', '#performance')
// logger.error('Failed to save data', '#database', '#error', new Error('DB connection failed'))
