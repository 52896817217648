import { defineNuxtPlugin } from '#app'
import * as Sentry from '@sentry/nuxt'

export default defineNuxtPlugin({
  name: 'subcode-supabase-auth-watcher',
  dependsOn: ['posthog'],
  async setup(_nuxtApp) {
    const { auth } = useSupabaseClient()
    const logger = useLogger()
    const posthog = await usePosthog()

    // Start watching for auth state changes
    auth.onAuthStateChange((event, session) => {
      logger.debug(`onAuthStateChange event`, '#onAuthStateChange', '#composable', {
        event,
        session,
      })

      if (event === 'INITIAL_SESSION') {
      // handle initial session
        logger.log(`Event: INITIAL_SESSION`, '#onAuthStateChange', '#auth', { event, session })

        // If user ID exists on session data, it should be the user's supabase ID
        if (session?.user?.id) {
          // -- Set user identity in tooling --

          // POSTHOG - If a user is logged in, we will add the context of the person to posthog calls
          if (posthog) {
            posthog.capture('user_authenticated')

            // Set the user identity in posthog
            posthog.identify(
              session?.user?.id, // Required. Replace 'distinct_id' with your user's unique identifier
              {
                email: session?.user?.email,
                name: session?.user?.user_metadata?.name, // ! name may be in a different place depending on the auth method
              }, // $set, optional
              // { first_visited_url: '/blog' }, // $set_once, optional
            )

            logger.debug(`User identity set following INITIAL_SESSION event`, '#posthog')
          }
          else {
            logger.warn('Posthog not available', '#posthog')
          }
        }

        // Sentry - Set the user identity in Sentry
        if (Sentry) {
          Sentry.setUser({
            id: session?.user?.id,
            email: session?.user?.email,
            name: session?.user?.user_metadata?.name,
          })
          logger.debug(`Sentry user set following INITIAL_SESSION event`, '#sentry', '#auth')
        }
        else {
          logger.warn('Sentry not available to set post-auth user identity', '#sentry')
        }
      }
      else if (event === 'SIGNED_IN') {
      // handle sign in event
        logger.log(`Event: SIGNED_IN`, '#onAuthStateChange', '#auth', { event, session })

        // Record login event to posthog and google analytics
        useEventCapture('login')
      }
      else if (event === 'SIGNED_OUT') {
      // handle sign out event
        logger.log(`Event: SIGNED_OUT`, '#onAuthStateChange', '#auth', { event, session })

        // POSTHOG - Reset after logout
        if (posthog) {
          posthog.reset() // Clears the user identity state after logout
          logger.debug(`Posthog reset, identity cleared following logout`, '#posthog', '#auth')
        }

        // Sentry - Reset after logout
        if (Sentry) {
          Sentry.setUser(null)
          logger.debug(`Sentry user reset, identity cleared following logout`, '#sentry', '#auth')
        }

        // Record login event to posthog and google analytics
        useEventCapture('logout')
      }
      else if (event === 'PASSWORD_RECOVERY') {
      // handle password recovery event
        logger.log(`Event: PASSWORD_RECOVERY`, '#onAuthStateChange', '#auth', { event, session })
      }
      else if (event === 'TOKEN_REFRESHED') {
      // handle token refreshed event
        logger.log(`Event: TOKEN_REFRESHED`, '#onAuthStateChange', '#auth', { event, session })
      }
      else if (event === 'USER_UPDATED') {
      // handle user updated event
        logger.log(`Event: USER_UPDATED`, '#onAuthStateChange', '#auth', { event, session })
      }
    })
  },
})
