export default async function () {
  const { $posthog } = useNuxtApp()
  if ($posthog) {
    const posthog = $posthog()
    return posthog
  }
  else {
    useLogger().warn('Posthog is not available')
  }
}
