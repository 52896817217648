// Template for sublinks:
// const topNavPSubLinks: { title: string, href: string, description: string, icon: string, enabled: boolean, beta: boolean}[] = [
//   {
//     title: 'Link',
//     href: '#',
//     description:
//       'Placeholder link.',
//     icon: 'lucide:corner-down-right',
//   },
// ]

export interface NavSubLinks {
  title: string
  href: string
  description: string
  icon: string
  enabled: boolean
  beta: boolean
}

// Explore
const topNavExploreSubLinks: NavSubLinks[] = [
  {
    title: 'Explore Map',
    href: '/app/explore',
    description:
      'See all projects on the map.',
    icon: 'soilicon:map-01',
    enabled: true,
    beta: false,
  },
  {
    title: 'Open RFQs',
    href: '/app/explore/requirements',
    description:
        'Browse open RFQs on the map.',
    icon: 'soilicon:marker-pin-01',
    enabled: false,
    beta: true,
  },
  {
    title: 'My Projects',
    href: '/app/explore/projectmap',
    description:
        'Your organisations projects mapped.',
    icon: 'soilicon:marker-pin-04',
    enabled: true,
    beta: false,
  },
  {
    title: 'Pinned Projects',
    href: '/app/explore/pinned',
    description:
        'Your pinned projects.',
    icon: 'soilicon:pin-02',
    enabled: true,
    beta: true,
  },
]

// Manage
const navigationManageSubLinks: NavSubLinks[] = [
  {
    title: 'My Projects',
    href: '/app/manage/projects',
    description:
      'Your projects.',
    icon: 'soilicon:folder-search',
    enabled: true,
    beta: false,
  },
  {
    title: 'Imports',
    href: '/app/manage/imports',
    description:
      'Your material import requirements.',
    icon: 'soilicon:upload-03',
    enabled: true,
    beta: true,
  },
  {
    title: 'Exports',
    href: '/app/manage/exports',
    description:
      'Your material export requirements.',
    icon: 'soilicon:download-03',
    enabled: true,
    beta: true,
  },
  {
    title: 'CO2e Report',
    href: '/app/manage/co2e',
    description:
      'CO2e reports for your projects.',
    icon: 'soilicon:sticker-circle',
    enabled: true,
    beta: true,
  },
  {
    title: 'AI Insights',
    href: '/app/manage/insights',
    description:
      'Insights into your material requirements.',
    icon: 'soilicon:stars-02',
    enabled: true,
    beta: true,
  },
]

// Directory
const navigationDirectorySubLinks: NavSubLinks[] = [
  {
    title: 'Open RFQs',
    href: '/app/directory/rfq',
    description:
      `Find opportunities to bid on projects.`,
    icon: 'soilicon:scales-01',
    enabled: true,
    beta: false,
  },
  {
    title: 'Suppliers',
    href: '/companies',
    description:
      `Browse companies in the construction industry.`,
    icon: 'soilicon:book-closed',
    enabled: true,
    beta: false,
  },
  {
    title: 'Project Directory',
    href: '/app/directory/global-projects',
    description:
       'All national construction projects.',
    icon: 'soilicon:book-open-01',
    enabled: true,
    beta: false,
  },
  {
    title: 'Saved Searches',
    href: '/app/directory/saved',
    description:
      `Your saved searches.`,
    icon: 'soilicon:star-01',
    enabled: true,
    beta: true,
  },
  {
    title: 'TEST DATA',
    href: '/app/directory/test-projects',
    description:
      `Test view of project data.`,
    icon: 'soilicon:star-01',
    enabled: false,
    beta: true,
  },

]

// Messages
const navigationMessagesSubLinks: NavSubLinks[] = [
  {
    title: 'Inbox',
    href: '/app/messages',
    description:
      'Your message inbox.',
    icon: 'soilicon:inbox-01',
    enabled: true,
    beta: false,
  },
  {
    title: 'Files',
    href: '/app/communication/files',
    description:
      'Shared files.',
    icon: 'soilicon:file-06',
    enabled: true,
    beta: true,
  },
  {
    title: 'Archive',
    href: '/app/communication/archive',
    description:
      'Your archived messages.',
    icon: 'soilicon:archive',
    enabled: true,
    beta: true,
  },
]

// Sales
const navigationSalesSubLinks: NavSubLinks[] = [
  {
    title: 'RFQ Inbox',
    href: '/app/sales/inbox',
    description:
      'Your RFQ inbox.',
    icon: 'soilicon:inbox-01',
    enabled: true,
    beta: false,
  },
  {
    title: 'My Bids',
    href: '/app/sales/bids',
    description:
      'Respond to RFQs.',
    icon: 'soilicon:send-03',
    enabled: true,
    beta: false,
  },
  {
    title: 'Opportunities',
    href: '/app/sales/opportunities',
    description:
      'Find opportunities for your company.',
    icon: 'soilicon:target-04',
    enabled: true,
    beta: true,
  },
  {
    title: 'Company Profile',
    href: '/app/sales/profile',
    description:
      'Edit your company profile.',
    icon: 'soilicon:check-verified-03',
    enabled: true,
    beta: true,
  },
  {
    title: 'Lead Notifications',
    href: '/app/sales/notifications',
    description:
      'Get notified for new opportunities.',
    icon: 'soilicon:bell-04',
    enabled: true,
    beta: true,
  },
]

const topNavMoreSubLinks: NavSubLinks[] = [
  {
    title: 'Companies',
    href: '/companies',
    description:
      'Explore the Soil Link directory.',
    icon: 'lucide:corner-down-right',
    enabled: true,
    beta: false,
  },
  {
    title: 'Directory',
    href: '/app/directory/global-projects',
    description:
      `All public projects.`,
    icon: 'lucide:corner-down-right',
    enabled: true,
    beta: false,
  },
  {
    title: 'Bids',
    href: '/app/directory/rfq',
    description:
      `View your bids in one place.`,
    icon: 'lucide:corner-down-right',
    enabled: true,
    beta: false,
  },
  {
    title: 'Map Dev 1',
    href: '/app/explore/map-dev',
    description:
      'Developer tools.',
    icon: 'lucide:corner-down-right',
    enabled: true,
    beta: false,
  },
  {
    title: 'Map Dev 2',
    href: '/app/explore/map-demo',
    description:
      'Developer tools.',
    icon: 'lucide:corner-down-right',
    enabled: true,
    beta: false,
  },
]

export default defineAppConfig({

  // Layout Config
  layout: {
    topbar: false,
    sidebar: true,
  },

  // Feature Flags
  ff: {
    projects: {
      sidebarFilters: true,
      statsCards: true,
      importButton: false,
      recentActivity: false,
    },
    bot: {
      globalBubble: false,
    },
  },

  // Nuxt Icon runtime config
  icon: {
    // size: '24px', // default <Icon> size applied
    // class: 'icon', // default <Icon> class applied
    mode: 'svg', // default <Icon> mode applied
    aliases: {
      nuxt: 'logos:nuxt-icon',
    },
  },

  // Nav Settings
  alwaysShowBetaItemsInNav: false,

  // Nav Top Bar Settings
  navigationSettingsTopBar: {
    showTopLevelTitles: false,
  },

  // Nav Side bar settings
  navigationSettingsSidebar: {
    showTopLevelTitles: false,
  },

  // Navigation
  navigationRootLinks: [
    {
      name: 'Explore',
      icon: 'soilicon:globe-06',
      href: '/app/explore',
      enabled: true,
      beta: false,
      components: topNavExploreSubLinks,
    },
    {
      name: 'Manage',
      icon: 'soilicon:folder',
      href: '/app/manage',
      enabled: true,
      beta: false,
      components: navigationManageSubLinks,
    },
    {
      name: 'Directory',
      icon: 'soilicon:book-open-02',
      href: '/app/directory',
      enabled: true,
      beta: false,
      components: navigationDirectorySubLinks,
    },
    {
      name: 'Messages',
      icon: 'soilicon:message-square-01',
      href: '/app/messages',
      enabled: true,
      beta: false,
      components: navigationMessagesSubLinks,
    },
    {
      name: 'Sales',
      icon: 'soilicon:rocket-02',
      href: '/app/sales',
      enabled: false,
      beta: true,
      components: navigationSalesSubLinks,
    },

    {
      name: 'More',
      icon: 'lucide:database',
      href: '#',
      enabled: false,
      beta: true,
      components: topNavMoreSubLinks,

    },
  ],

  // layoutSidebarTopNavLinks: [
  //   { name: 'Home', icon: 'lucide:home', href: '/app/projects' },
  //   { name: 'Dashboard', icon: 'lucide:layout-dashboard', href: '/app/dashboard' },
  //   { name: 'Projects', icon: 'lucide:folder-search', href: '/app/projects' },
  //   { name: 'Reporting', icon: 'lucide:bar-chart-big', href: '/app/reports' },
  // ],

  // layoutSidebarBottomNavLinks: [
  //   { name: 'Developer Tools', icon: 'lucide:code', href: '/dev' },
  //   // { name: 'Users', icon: Users, href: '/app/users' },
  //   { name: 'Team', icon: 'lucide:users', href: '/app/team' },
  //   { name: 'Settings', icon: 'lucide:settings', href: '/app/settings' },
  // ],

  bots: {
    // previewMessage: {
    //   message: 'Here to help if you need me!',
    //   autoShowDelay: 2000,
    //   // avatarUrl: 'https://avatars.githubusercontent.com/u/16015833?v=4',
    // },
    previewMessage: null,
    theme: {
      placement: 'left',
      button: { backgroundColor: '#ed1152' },
      chatWindow: {
        backgroundColor:
          'https://imagedelivery.net/7lMl5Reh_fzm6xAZ34qE_w/357c5325-6817-4a05-0314-07bb40a29500/public',
        // maxWidth: '100%',
      },
    },
  },
})
